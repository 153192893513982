import * as Realm from 'realm-web'

let realm: Realm.App | null = null

type RealmApp = Realm.App & {
  currentUser: any
}

export const getRealm = (): RealmApp => {
  if (realm !== null)
    return realm

  realm = new Realm.App({ id: 'msshop2-nwtia' })

  return realm
}

export const getDB = async (name = 'ecomm1') => {
  const app = getRealm()
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.anonymous())
  }

  return app.currentUser?.mongoClient('mongodb-atlas').db(name)
}

export const mustGetDB = async (
  name = 'ecomm1',
): Promise<any> => {
  const db = await getDB(name)
  if (db === null)
    throw new Error('DB connection failed')

  return db
}

export const getCollection = async (dbName: string, collectionName: string) => {
  const db = await getDB(dbName)
  if (!db)
    throw new Error('DB connection failed')

  return db.collection(collectionName)
}
